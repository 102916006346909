.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-items: center;
}

/* Style gallery items */
.gallery-item {
  background-color: #1e1e1e;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  max-width: 300px;
  padding: 10px;
}

/* Constrain image and video sizes */
.gallery-item img,
.gallery-item video {
  width: 100%;
  height: auto;
  object-fit: cover;
  flex-grow: 1;
  border-radius: 8px 8px 0 0;
  transition: transform 0.3s;
}

/* Hover effect for images/videos */
.gallery-item img:hover,
.gallery-item video:hover {
  transform: scale(1.05);
}

.gallery-item-text {
  font-style: italic;
  color: #e0e0e0;
  margin-top: 10px;
  text-align: center;
  padding: 0 10px;
}
