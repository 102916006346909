/* Dark Mode Global Styles */
body {
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  margin-top: 50px;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text */
}

/* Navigation bar */
nav {
  background-color: #1c1c1c; /* Darker navbar */
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px; /* Set height of navbar to your desired size */
  display: flex;
  align-items: center; /* Align logo and menu links vertically centered */
  justify-content: space-between;
  z-index: 1;
}

/* Logo styling */
.logo {
  color: white;
  width: 100px;
  height: auto;
  margin-top: 70px;
  margin-left: 40px;
}

/* Navigation list styling */
nav ul {
  display: flex;
  list-style-type: none;
  margin-right: 40px;
}

/* Navigation list item styling */
nav ul li {
  margin-right: 1.5em;
}

/* Navigation link styling */
nav ul li a,
.nav-links a {
  color: #e0e0e0; /* Light text for links */
  text-decoration: none;
}

/* Hamburger menu styling */
.hamburger {
  display: none; /* Hidden by default */
  left: 10px;
  font-size: 2.5em;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  margin: 0;
}

/* Navigation links styling */
.nav-links {
  transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transform: translateX(100%);
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #333;
  overflow: auto;
}

/* Style for open navigation links */
.nav-links.open {
  transform: translateX(0);
  visibility: visible;
}

/* Style for homepage and sections */
.homepage, .about-me, .skills-grid, .experience-list, .contact-section {
  margin: 0 auto;
  padding: 20px;
  max-width: 900px;
}

/* Style for headings */
.homepage h1, .homepage h2, .homepage h3 {
  color: #00bfff; /* Bright accent color for headings */
  margin-top: 50px;
}

.homepage .about-me,
.homepage .skills-grid,
.homepage .experience-list,
.homepage .contact-section {
  padding: 0;
  margin: 0;
}

.highlighted {
  color: #00bfff; /* Same blue color as section headers */
}

.homepage p {
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
  margin-bottom: 16px; /* Add a consistent bottom margin for spacing */
  text-align: left; /* Align text to the left, matching section headers */
  line-height: 1.6; /* Improve readability with line height */
  color: #e0e0e0; /* Ensure text color matches theme */
}

/* About Me Section */
.about-me {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column; /* Flex column for better stacking */
}

/* Profile Picture */
.homepage .about-me .profile-picture {
  width: 150px;
  border-radius: 50%;
}

/* Skills, Experience, and Contact Grids */
.skills-grid, .experience-list, .contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  list-style-type: none;
  padding: 0;
  margin-top: 16px;
}

.skills-grid div, .experience-list li, .contact-grid a {
  display: flex; /* Use Flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: #1e1e1e; /* Darker background for cards */
  color: #27ad74; /* Highlight color for text */
  border: 1px solid #333; /* Subtle border */
  border-radius: 8px;
  padding: 12px;
  min-height: 60px; /* Ensure consistent height */
  text-align: center; /* Center text inside */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s, transform 0.2s, box-shadow 0.2s;
}

.skills-grid div:hover, .experience-list li:hover, .contact-grid a:hover {
  background-color: #333;
  color: #9bf1ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

/* Contact Section */
.contact-section {
  color: #e0e0e0;
  margin-top: 20px;
  border-radius: 8px; /* Match other sections */
}

.contact-section h2 {
  color: #00bfff;
  margin-bottom: 16px;
}

.contact-section p {
  margin: 8px 0;
  margin-bottom: 20px; /* Add spacing below paragraph */
}

/* Style for obfuscated email */
.homepage .email-obfuscate {
  unicode-bidi: bidi-override;
  direction: rtl;
}

/* Footer styling */
footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #1c1c1c;
  color: #e0e0e0;
  text-align: center;
  font-size: small;
  padding: 0.1em;
}

/* Mobile-first media queries */
@media (max-width: 768px) {
  .hamburger {
    display: block; /* Only show on mobile */
  }

  .nav-links li {
    margin: 1em 0; /* Increase space between links */
  }

  .nav-links a {
    font-size: 1.2em; /* Increase font size for mobile */
  }

  .about-me {
    flex-direction: column;
    align-items: center;
  }

  .skills-grid, .experience-list, .contact-grid {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }

  .logo {
    font-size: 1.2em;
  }

  footer {
    font-size: 0.8em;
  }
}

@media (max-width: 600px) {
  .logo {
    width: 70px;
  }
}

@media (max-width: 400px) {
  .logo {
    width: 50px;
  }
}

@media (min-width: 769px) {
  .nav-links {
    transform: none;
    visibility: visible;
    position: relative;
    height: auto;
    width: auto;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: transparent;
    overflow: visible;
  }
}
