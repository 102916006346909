.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.modal {
  width: 90vw;
  max-width: 600px;
  max-height: 80vh;
  background-color: #2a2a2a;
  color: #e0e0e0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  border: 1px solid #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.modal-photo-container {
  width: 100%;
  max-height: 70vh; /* Ensure it fits within the modal */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e1e1e;
  border-radius: 8px;
  overflow: hidden;
}

.modal-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

.modal-text {
  font-style: italic;
  color: #b0b0b0;
  margin-top: 10px;
  text-align: center;
  max-height: 20vh;
  overflow-y: auto;
  width: 100%;
  padding: 0 10px;
}

.copy-tab {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: #444;
  color: #e0e0e0;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.copy-tab:hover {
  background-color: #555;
  color: #9bf1ff;
}

.copy-tab.copied {
  background-color: #27ad74;
  color: #fff;
  transform: scale(1.05);
}
