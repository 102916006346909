.map-container {
  height: calc(100vh - 70px);
  width: 100%;
  z-index: 0;
}

@media (max-width: 768px) {
  .map-container {
    height: calc(100vh - 70px);
  }
}

.public-map-page div {
  margin-top: 0;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-message {
  font-size: 24px;
  animation: pulse 1s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

.popup-content {
  max-width: 200px;
}

.photos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.photos-container .photo {
  max-width: 100%;
  height: auto;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
}

.modal-photo {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.modal-close {
  display: block;
  margin: 0 auto;
}