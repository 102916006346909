/* BlogPage.css */

/* 
Main container for the blog page.
Center aligns child elements and sets the page padding.
*/
.blog-container {
  max-width: max-content;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 
Defines the list of blog posts.
Organizes them in a centered column.
*/
.blog-list {
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
}

/* 
Sets style for the blog title.
Centers the text and sets the color.
*/
.blog-container h1 {
  width: 100%;
  text-align: center; 
  color: #00bfff;
}

/* 
Defines individual blog post elements.
Sets width, border, and padding.
*/
.blog-post {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  width: 70%;
}

/* 
Styles for the date in a blog post.
Smaller font and a light color.
*/
.blog-post .date {
  font-size: 0.8em;
  color: #888;
}

/* 
Tags within a blog post.
Sets margin and styles for individual tag spans.
*/
.blog-post .tags {
  margin: 10px 0;
}
.blog-post .tags span {
  background-color: #00bfff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}

/* 
Styles for the blog post text.
Sets margin and aligns text.
*/
.blog-post p {
  margin: 10px 0;
  text-align: justify;
}

/* 
Main content container.
Sets spacing and alignment for child elements.
*/
.content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 
Tag container.
Defines width and order.
*/
.tags-container {
  width: 25%;
  order: 2;
}

/* 
Styles for the tag buttons.
Defines background color, color, padding, border-radius, and margin.
*/
.tag-button {
  background-color: #00bfff;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
}

/* 
Header within the tags container.
Sets color and positions it at a sticky location.
*/
.tags-container h3 {
  color: #00bfff;
  position: sticky;
  top: 20px;
}

/* 
Defines styling for blog preview.
Sets position, padding, and flex properties.
*/
.blog-preview {
  position: relative;
  padding-bottom: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* 
Sets gradient fading for blog preview.
*/
.blog-preview::after {
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(transparent, white);
}

/* 
Sets style for the "Continue reading" link.
Aligns it to the end.
*/
.continue-reading {
  align-self: flex-end;
}

/* 
Sets responsive styling for smaller screens.
Adjusts padding of the blog container.
*/
@media (max-width: 768px) {
  .blog-container {
    padding: 20px 10px;
  }
}
