/* PostPage.css */

.post-container {
    max-width: max-content;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .post-container h1 {
    width: 100%;
    text-align: center;
    color: #00bfff;
  }
  
  .post-content {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
    width: 70%;
  }
  
  .post-content .date {
    font-size: 0.8em;
    color: #888;
  }
  
  .post-content .tags {
    margin: 10px 0;
  }
  
  .post-content .tags span {
    background-color: #00bfff;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    margin-right: 5px;
  }
  
  .post-content p {
    margin: 10px 0;
    text-align: justify;
  }
  
  @media (max-width: 768px) {
    .post-container {
      padding: 20px 10px;
    }
  }
  